import React, { FC } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";

import styles from "./BackButton.module.scss";


const BackButton: FC = () => {
  return (
    <div className={styles.container}>
      <Link to="/">
        <Icon className={styles.icon} icon={faReply} />
      </Link>
    </div>
  );
};

export default BackButton;