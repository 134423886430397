import React, { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { account } from "../store";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

import styles from "./RootButton.module.scss";


const RootButton: FC = observer(() => {
  const overUser = account.user && account.user.id !== (account.user.root && account.user.root.id);
  return (
    <div className={[styles.container, overUser && styles.overUser].join(" ")}>
      <Link to="/root">
        <Icon className={styles.icon} icon={faUserSecret} />
      </Link>
    </div>
  );
});

export default RootButton;