import React, { FC, useState } from "react";
import { history, account } from "../../store";
import BasicFrame from "../../components/BasicFrame";
import Dialog from "../../components/Dialog";

import styles from "./index.module.scss";

const Profile: FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [email, setEmail] = useState(
    account.user ? account.user.person.email || "" : ""
  );
  return (
    <BasicFrame title="Профиль">
      <Dialog title="Редактирование Email" show={showDialog}>
        <span>
          На указанный EMAIL будет отправлена ссылка для подтверждения
        </span>
        <input
          type="text"
          placeholder="EMAIL"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <div className={styles.dialogButtonsContainer}>
          <button onClick={e => setShowDialog(false)} className="secondary">
            Отмена
          </button>
          <button
            onClick={e => {
              account.setEmail(email);
              setShowDialog(false);
              history.replace("/");
            }}
          >
            Сохранить
          </button>
        </div>
      </Dialog>
      <div className={styles.container}>
        <dl>
          <dd>ФИО</dd>
          <dt>{account.user && account.user.person.fio}</dt>
          <dd>Телефон</dd>
          <dt>{account.user && account.user.person.phoneNo}</dt>
          <dd>EMAIL</dd>
          <dt>
            {account.user &&
              (account.user.person.email || (
                <span className={styles.attention}>НЕ УКАЗАН</span>
              ))}
          </dt>
        </dl>
        <div className={styles.hint}>
          <b>
            Для получения ЭЛЕКТРОННЫХ ЧЕКОВ по операциям пополнения своих счетов
            необходимо обязательно указать EMAIL. В скором времени водители не
            указавшие EMAIL не смогут пополнять свои счета.
          </b>
        </div>
        <button onClick={e => setShowDialog(true)} className="secondary">
          Заполнить EMAIL
        </button>
        <div className={styles.hint}>
          В соответствии с п. 1 ст. 1.2 Федерального закона от 22.05.2003 №
          54-ФЗ “О применении контрольно-кассовой техники при осуществлении
          наличных денежных расчетов и (или) расчетов с использованием
          электронных средств платежа” контрольно-кассовая техника рименяется на
          территории РФ в обязательном порядке всеми организациями при
          осуществлении ими расчетов.
        </div>
        <div className={styles.buttonsContainer}>
          <button onClick={e => history.goBack()} className="secondary">
            Назад
          </button>
        </div>
      </div>
    </BasicFrame>
  );
};

export default Profile;
