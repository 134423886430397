import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { history, data } from "../../store";
import BasicFrame from "../../components/BasicFrame";
import Select from "../../components/Select";

import styles from "./index.module.scss";
import { IShifts, IPeriod, IShift, IFlows } from "../../models";

const periods: Array<[string, any]> = [
  [
    "Текущие сутки",
    {
      bgn: moment()
        .startOf("day")
        .toDate(),
      end: moment()
        .endOf("day")
        .toDate()
    }
  ],
  [
    "Прошедшие сутки",
    {
      bgn: moment()
        .add(-1, "day")
        .startOf("day")
        .toDate(),
      end: moment()
        .add(-1, "day")
        .endOf("day")
        .toDate()
    }
  ],
  [
    "Текущая неделя",
    {
      bgn: moment()
        .startOf("week")
        .toDate(),
      end: moment()
        .endOf("week")
        .toDate()
    }
  ],
  [
    "Прошедшая неделя",
    {
      bgn: moment()
        .add(-1, "week")
        .startOf("week")
        .toDate(),
      end: moment()
        .add(-1, "week")
        .endOf("week")
        .toDate()
    }
  ],
  [
    "Текущий месяц",
    {
      bgn: moment()
        .startOf("month")
        .toDate(),
      end: moment()
        .endOf("month")
        .toDate()
    }
  ],
  [
    "Прошедший месяц",
    {
      bgn: moment()
        .add(-1, "month")
        .startOf("month")
        .toDate(),
      end: moment()
        .add(-1, "month")
        .endOf("month")
        .toDate()
    }
  ]
];
const groups: Array<[string, any]> = [
  ["Смены", ""],
  ["Путевые листы", "shifts"]
];

interface IDayStat {
  shift: IShift;
  orderBonus: number;
}

const DayStat: FC<IDayStat> = ({ shift, orderBonus }) => {
  return (
    <React.Fragment>
      <div className={styles.title}>
        {moment(shift.start).format("DD MMM HH:mm")}-
        {moment(shift.end).format("HH:mm")}
      </div>
      <dl className={styles.balance}>
        <dt>Заказов</dt>
        <dd>{shift.quantity}</dd>
        <dt>Сумма</dt>
        <dd>{shift.sum} ₽</dd>
        <dt>Из них на счёт</dt>
        <dd>{shift.beznalSum || 0} ₽</dd>
        <dt>Списано за заказы</dt>
        <dd>{shift.paySum} ₽</dd>
        <dt>Доплачено за заказы</dt>
        <dd>{shift.chargeSum} ₽</dd>
        <dt>Начислено бонусов</dt>
        <dd>{orderBonus || 0} ₽</dd>
        <dt>Средняя цена</dt>
        <dd>{shift.avgSum} ₽</dd>
        <dt>Продолжительность</dt>
        <dd>
          {moment
            // @ts-ignore
            .duration(moment(shift.end) - moment(shift.start))
            .humanize()}
        </dd>
        <dt>Перерывы</dt>
        <dd>
          {shift.idlesDuration > 60000
            ? moment.duration(shift.idlesDuration).humanize()
            : "--"}
        </dd>
      </dl>
    </React.Fragment>
  );
};

const Statistics: FC = () => {
  const groupByIndex = data.getGroupBy();
  const groupByValue = groups[groupByIndex][1];
  const [groupBy, setGroupBy] = useState<"shift" | undefined>(groupByValue);
  const periodIndex = data.getPeriod();
  const periodValue = periods[periodIndex][1];
  const [period, setPeriod] = useState<IPeriod>(periodValue);
  const [shifts, setShifts] = useState<IShifts | null>();
  const [flows, setFlows] = useState<IFlows | null>();
  useEffect(() => {
    data.getOrders(period, groupBy).then(setShifts);
    data.getFlows(period).then(setFlows);
  }, [period, groupBy]);
  const duration =
    shifts &&
    shifts.data &&
    moment.duration(
      shifts.data.reduce((s: number, i: IShift) => {
        // @ts-ignore
        s += moment(i.end) - moment(i.start);
        return s;
      }, 0)
    );
  const orderBonus =
    flows &&
    flows.data &&
    flows.data
      .filter(i => i.code === "ORDER_ADD_BONUS")
      .reduce((s, i) => s + i.value / 100, 0);
  return (
    <BasicFrame title="Статистика">
      <div className={styles.container}>
        <Select
          title="Период"
          items={periods}
          selected={periodIndex}
          onSelect={(i: any, k: number) => {
            setPeriod(i);
            data.setPeriod(k);
          }}
        />
        <Select
          title="Группировка"
          items={groups}
          selected={groupByIndex}
          inSeries={true}
          onSelect={(i: any, k: number) => {
            setGroupBy(i);
            data.setGroupBy(k);
          }}
        />
        {shifts && shifts.data.length > 1 && (
          <React.Fragment>
            <div className={styles.title}>Общая статистика</div>
            <dl className={styles.balance}>
              <dt>Заказов</dt>
              <dd>{shifts.quantity}</dd>
              <dt>Смен</dt>
              <dd>{shifts.data.length}</dd>
              <dt>Сумма</dt>
              <dd>{shifts.sum} ₽</dd>
              <dt>Из них на счёт</dt>
              <dd>{shifts.beznalSum} ₽</dd>
              <dt>Списано за заказы</dt>
              <dd>{shifts.paySum} ₽</dd>
              <dt>Доплачено за заказы</dt>
              <dd>{shifts.chargeSum} ₽</dd>
              <dt>Начислено бонусов</dt>
              <dd>{orderBonus || 0} ₽</dd>
              <dt>Средняя цена</dt>
              <dd>{shifts.avgSum} ₽</dd>
              <dt>Продолжительность</dt>
              <dd>
                {duration && duration > moment.duration(1, "minute")
                  ? duration.humanize()
                  : 0}
              </dd>
              <dt>Перерывы</dt>
              <dd>
                {shifts.idlesDuration > 60000
                  ? moment.duration(shifts.idlesDuration).humanize()
                  : "--"}
              </dd>
            </dl>
          </React.Fragment>
        )}
        {shifts &&
          shifts.data
            .sort((a, b) => (a.start > b.start ? -1 : 1))
            .map((i: IShift, k: number) => (
              <DayStat
                key={k}
                shift={i}
                orderBonus={
                  (flows &&
                    flows.data &&
                    flows.data
                      .filter(
                        f =>
                          f.code === "ORDER_ADD_BONUS" &&
                          moment(f.createdAt) >= moment(i.start) &&
                          moment(f.createdAt) <= moment(i.end)
                      )
                      .reduce((s, f) => s + f.value / 100, 0)) ||
                  0
                }
              />
            ))}
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
        <button onClick={e => history.push("/orders")}>Заказы</button>
      </div>
    </BasicFrame>
  );
};

export default Statistics;
