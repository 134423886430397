import api from "./index";
import {
  IBalance,
  IShifts,
  IPeriod,
  IAccount,
  IFlows,
  ICar,
  IPlanRecords,
  IPaymentFor,
  ITransfer,
  ICarPossibility,
  ICarPossibilities,
  ICarPossibilityType,
  IReservedOrdersRequest
} from "../models";

export class DataAPI {
  static async getBalance(): Promise<IBalance> {
    return api.request("GET", "/user/balance", {});
  }

  static async getYandexBalance(): Promise<IBalance> {
    return api.request("GET", "/user/yandexBalance", {});
  }

  static async getAvailablePayments(): Promise<IPaymentFor[]> {
    return api.request("GET", "/user/paymentFor", {});
  }

  static async getBalanceHistory(period: IPeriod): Promise<any> {
    return api.request("GET", "/user/balance2/history", {
      query: {
        bgn: period.bgn.toJSON(),
        end: period.end.toJSON()
      }
    });
  }

  static async transfer(transfer: ITransfer): Promise<any> {
    return api.request("POST", "/user/transfer", {
      body: transfer
    });
  }

  static async getCars(): Promise<ICar[]> {
    return api.request("GET", "/user/cars", {});
  }

  static async getOrders(
    period: IPeriod,
    groupBy?: "shift" | ""
  ): Promise<IShifts> {
    return api.request("GET", "/user/orders", {
      query: {
        bgn: period.bgn.toJSON(),
        end: period.end.toJSON(),
        groupBy
      }
    });
  }

  static async getAccounts(): Promise<IAccount[]> {
    return api.request("GET", "/user/fin/accounts", {});
  }

  static async getFlows(period: IPeriod, accountId?: number): Promise<IFlows> {
    return api.request("GET", "/user/fin/flows", {
      query: {
        bgn: period.bgn.toJSON(),
        end: period.end.toJSON(),
        limit: 0,
        accountIds: accountId ? [accountId] : undefined
      }
    });
  }

  static async getPlanRecords(
    period: IPeriod,
    carId?: number
  ): Promise<IPlanRecords> {
    return api.request("GET", "/user/planRecords", {
      query: {
        from: period.bgn.toJSON(),
        to: period.end.toJSON(),
        carId
      }
    });
  }

  static async findWashPossibilities(
    date: string,
    garageId: number
  ): Promise<any> {
    return api.request("GET", "/user/washPossibilities", {
      query: {
        date,
        garageId
      }
    });
  }

  static async findWashPossibilitiesAll(
    period: IPeriod,
    carId: number
  ): Promise<any> {
    return api.request("GET", "/user/washPossibilitiesAll", {
      query: {
        from: period.bgn.toJSON(),
        to: period.end.toJSON(),
        carId
      }
    });
  }

  static async findCarPossibilities(
    period: IPeriod,
    carId: number,
    types?: ICarPossibilityType[]
  ): Promise<ICarPossibilities> {
    return api.request("GET", "/user/carPossibilities", {
      query: {
        from: period.bgn.toJSON(),
        to: period.end.toJSON(),
        carId,
        types
      }
    });
  }

  static async removeCarPossibility(id: number): Promise<any> {
    return api.request("DELETE", `/user/carPossibilities/${id}`, {
      query: {}
    });
  }

  static async addCarPossibility(body: ICarPossibility): Promise<any> {
    return api.request("POST", `/user/carPossibilities`, { body });
  }

  static async getRevervedOrders(): Promise<any> {
    return api.request("GET", `/offers/reserved`, {});
  }

  static async getReservedOrdersList(): Promise<any> {
    return api.request("GET", `/offers/reservedRequestes`, {});
  }

  static async createReservedOrdersRequest(body: IReservedOrdersRequest): Promise<any> {
    return api.request("POST", `/offers/reservedRequestes`, { body });
  }

  static async removeReservedOrdersRequest(requestId: number): Promise<any> {
    return api.request("DELETE", `/offers/reservedRequestes/${requestId}`, {});
  }
}
