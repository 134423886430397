import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {remarks2string, pluralize} from "../../utils";

import { history, data } from "../../store";
import BasicFrame from "../../components/BasicFrame";

import styles from "./index.module.scss";
import { IPeriod, IOrder } from "../../models";

const Route: FC<{ src: string; dst: string; count: number }> = ({
  src,
  dst,
  count
}) => {
  const interimAdds = `${count - 2} ${pluralize(count - 2, ['промежуточный адрес', 'промежуточных адреса', 'промежуточных адресов'])}`;
  return (
    <div className={styles.route}>
      <div className={styles.addsContainer}>
        <div className={styles.iconContainer}>
          <Icon icon={faDotCircle} className={styles.icon} />
        </div>
        <div className={styles.adds}>{src}</div>
      </div>
      {count > 2 && (
        <div className={styles.addsContainer}>
          <div className={styles.iconContainer}>
            <Icon icon={faDotCircle} className={styles.icon} />
          </div>
          <div className={styles.adds}>{interimAdds}</div>
        </div>
      )}
      <div className={styles.addsContainer}>
        <div className={styles.iconContainer}>
          <Icon icon={faDotCircle} className={styles.icon} />
        </div>
        <div className={styles.adds}>{dst || "Не определён"}</div>
      </div>
    </div>
  );
};

const Order: FC<IOrder> = ({
  id,
  assignedAt,
  beginedAt,
  doneAt,
  cost,
  remarks,
  expectedCost,
  costCorrected,
  costDriver,
  costDriverCorrected,
  addressSourceTitle,
  addressDestTitle,
  routeActualCount
}) => {
  const remarksString = remarks && remarks2string(remarks);
  return (
    <div className={styles.order}>
      <div className={styles.line}>
        <div className={styles.time}>
          {moment(assignedAt).format("HH:mm")}
        </div>
        <div className={styles.cost}>
          {`${expectedCost || "--"} ₽`}
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.tiny}>
          №{id}
        </div>
        {remarksString && <div className={styles.remarks}> {remarksString} </div>}
      </div>
      <Route
        src={addressSourceTitle}
        dst={addressDestTitle}
        count={routeActualCount}
      />
    </div>
  );
};

interface IDayStat {
  shift: IPeriod;
}

const DayStat: FC<IDayStat> = ({ shift }) => {
  return (
    <React.Fragment>
      <div className={styles.title}>
        {moment(shift.bgn).format("DD MMMM YYYY")}
      </div>
      <div className={styles.orders}>
        {shift.orders && shift.orders.sort((a, b) => a.assignedAt < b.assignedAt ? -1 : 1).map((i, k) => <Order key={k} {...i} />)}
      </div>
    </React.Fragment>
  );
};

const OrdersReserved: FC = () => {
  const [orders, setOrders] = useState<IOrder[] | null>();
  const [shifts, setShifts] = useState<IPeriod[] | null>();
  useEffect(() => {
    data.getReservedOrders(true, false).then(setOrders);
  }, []);
  useEffect(() => {
    if (orders) {
      const shifts = orders.reduce((s: IPeriod[], i) => {
        const day = moment(i.assignedAt)
          .startOf("day")
          .toDate();
        const shift = s.find((p: IPeriod) => p.bgn.toJSON() === day.toJSON());
        if (shift) {
          if (shift.orders) {
            shift.orders.push(i);
          }
        } else {
          s.push({
            bgn: day,
            end: day,
            orders: [i]
          });
        }
        return s;
      }, []);
      setShifts(shifts);
    }
  }, [orders]);
  return (
    <BasicFrame title="Назначенные предварительные заказы">
      <div className={styles.container}>
        {shifts &&
          shifts
            .sort((a, b) => (a.bgn > b.end ? 1 : -1))
            .map((i: IPeriod, k: number) => <DayStat key={k} shift={i} />)}
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
      </div>
    </BasicFrame>
  );
};

export default OrdersReserved;
