import React, { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { account } from "../../store";
import RootButton from "../../components/RootButton";
import styles from "./index.module.scss";

import pkg from "../../../package.json";

interface IMenuItem {
  title: string;
  path?: string;
  action?: Function;
}

interface IMenu {
  menu: IMenuItem[];
}

const Menu: FC<IMenu> = observer(({ menu }) => {
  return (
    <div className={styles.container}>
      <div className="logo">
        <div className={styles.version}>{pkg.version}</div>
        {account.user && account.user.root && <RootButton />}
      </div>
      <div className={styles.menu}>
        {menu.map((i, k) =>
          i.path ? (
            <Link key={k} to={i.path}>
              {i.title}
            </Link>
          ) : (
            <div key={k} onClick={e => i.action && i.action()}>
              {i.title}
            </div>
          )
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={() => account.logout()} className="secondary">
          Выход
        </button>
      </div>
    </div>
  );
});

export default Menu;
