import React, { FC, useState, useEffect, useRef } from "react";
import { faCaretSquareDown } from "@fortawesome/free-regular-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import styles from "./Select.module.scss";

interface ISelect {
  title: string;
  items?: Array<[string, any]>;
  onSelect: Function;
  selected?: number;
  inSeries?: boolean;
}

const Select: FC<ISelect> = ({
  title,
  items = [],
  selected = 0,
  inSeries,
  onSelect
}) => {
  const el = useRef(null);
  const [selectedItem, setSelected] = useState(selected);
  const [subMenu, setSubMenu] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    if (items && items.length > 0) {
      if (inSeries) {
        const nextItem = selectedItem < items.length - 1 ? selectedItem + 1 : 0;
        setSelected(nextItem);
        onSelect(items[nextItem][1], nextItem);
      } else {
        setSubMenu(!subMenu);
      }
    }
  };

  const selectItem = (k: number) => {
    setSubMenu(false);
    if (k !== selectedItem) {
      setSelected(k);
      onSelect(items[k][1], k);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside, false);
  }, []);

  useEffect(() => {
    if (selected >= 0) {
      setSelected(selected);
    }
  }, [selected]);

  const handleClickOutside = (e: MouseEvent) => {
    // @ts-ignore
    if (el && el.current && el.current.contains(e.target)) {
      return;
    } else {
      setSubMenu(false);
    }
  };
  return (
    <React.Fragment>
      {subMenu && <div className={styles.shadow} />}
      <div className={styles.container} ref={el} onClick={onClick}>
        <div>{title}</div>
        <div className={styles.downList}>
          {(items.length && items[selectedItem] && items[selectedItem][0]) || "--"}
          <Icon
            icon={inSeries ? faSort : faCaretSquareDown}
            className={styles.icon}
          />
        </div>
        {subMenu && (
          <div className={styles.subMenuContainer}>
            <div className={styles.subMenu}>
              <div className={styles.subMenuTitle}>{title}</div>
              {items.map((i, k) => (
                <div
                  className={[
                    styles.subMenuItem,
                    selectedItem === k && styles.selected
                  ].join(" ")}
                  key={k}
                  onClick={e => selectItem(k)}
                >
                  {i[0]}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Select;
