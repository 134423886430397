import React, { FC } from "react";

import styles from "./Dialog.module.scss";

interface IDialog {
  title: string;
  children: any;
  show?: boolean;
}

const Dialog: FC<IDialog> = ({ title, children, show = false }) => {
  return show ? (
    <div className={styles.container}>
      <div className={styles.dialog}>
        <div className={styles.title}>{title}</div>
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  ) : (
    <React.Fragment/>
  );
};

export default Dialog;
