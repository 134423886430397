import qs from "qs";
import config from "../config";

interface IRequestOptions {
  body?: any;
  query?: Object;
}

class API {
  async request(
    method: "GET" | "POST" | "PUT" | "DELETE",
    path: string,
    { body, query }: IRequestOptions
  ): Promise<any> {
    const url =
      `${config.IST_URL}${path}` + (query ? "?" + qs.stringify(query) : "");
    let headers: any = {
      Accept: "application/json",
    };
    try {
      let response = await fetch(url, {
        method,
        headers,
        credentials: "include",
        body: JSON.stringify(body),
      });
      if (response.status !== 200) {
        let error;
        try {
          const json = await response.json();
          error = json.details;
        } catch (err) {
          error = response.statusText;
        }
        throw new Error(error);
      }
      try {
        const json = await response.json();
        return json;
      } catch (err) {
        return response;
      }
    } catch (ex) {
      if (ex.message === "Failed to fetch") {
        throw new Error("Проверьте интернет-соединение");
      } else {
        throw ex;
      }
    }
  }
}

const api = new API();
export default api;

export const ping = async (): Promise<any> => {
  // return { ok: true };
  return api.request("GET", "/ping", {});
};
