import { history } from "./history";
import { ui } from "./ui";
import { account } from "./account";
import { data } from "./data";
import { messages } from "./messages";
import { ping } from "../api";

export { history, ui, account, messages, data };

export async function wait(ms: number): Promise<NodeJS.Timeout> {
  return new Promise(resolve => {
    const timer: NodeJS.Timeout = setTimeout(() => resolve(timer), ms);
  });
}

export async function cache(fn: Function, ms: number): Promise<any> {
  const name = `cached_${fn.name}`;
  let prev: any = localStorage.getItem(name);
  try {
    prev = JSON.parse(prev);
  } catch(ex) {}
  let result;
  if (!prev || (prev.timestamp && prev.timestamp + ms < Date.now())) {
    result = await fn();
    localStorage.setItem(name, JSON.stringify({
      timestamp: Date.now(),
      result
    }))
  } else {
    result = prev.result;
  }
  return result;
}

function runInterval(proc: Function, interval: number) {
  proc();
  setInterval(proc, interval);
}

function checkOnline() {
  runInterval(async () => {
    try {
      await ping();
      ui.online = true;
    } catch (err) {
      ui.online = false;
    }
  }, 1000 * 5);
}

checkOnline();