import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { account } from "../../store";

import styles from "./index.module.scss";
import pkg from "../../../package.json";

interface IPin {}

const Pin: FC<IPin> = observer(() => {
  const [value, setValue] = useState("");
  const onEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      account.getPin(value);
    }
  }
  return (
    <div className={styles.container}>
      <div className="logo">
          <div className={styles.version}>{pkg.version}</div>
      </div>
      <label>Введите платёжный код</label>
      <input
        type="number"
        placeholder="Код"
        value={value}
        onChange={e => setValue(e.target.value)}
        autoFocus={true}
        onKeyPress={onEnter}
      />
      <button onClick={e => account.getPin(value)}>Отправить пароль</button>
    </div>
  );
});

export default Pin;
