import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { account } from "../../store";
import pkg from "../../../package.json";
import styles from "./index.module.scss";

interface ILogin {}

const Login: FC<ILogin> = observer(() => {
  const [value, setValue] = useState("");
  const onEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      account.login(value);
    }
  }
  return (
    <div className={styles.container}>
      <div className="logo">
        <div className={styles.version}>{pkg.version}</div>
      </div>
      <div className={styles.content}>
        <label>Введите Полученный PIN</label>
        <input
          type="number"
          placeholder="PIN"
          value={value}
          autoFocus={true}
          onChange={e => setValue(e.target.value)}
          onKeyPress={onEnter}
        />
        <button onClick={e => account.login(value)}>Войти</button>
        <button
          onClick={e => account.getPin(account.uin, true)}
          className="secondary"
        >
          Пин не пришёл
        </button>
      </div>
    </div>
  );
});

export default Login;
