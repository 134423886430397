import React, { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { IMenuItem } from "../models";
import { account, history } from "../store";
import RootButton from "./RootButton";
import styles from "./SubMenu.module.scss";

import pkg from "../../package.json";

interface IMenu {
  menu: IMenuItem[];
}

const Menu: FC<IMenu> = observer(({ menu }) => {
  return (
    <div className={styles.container}>
      <div className="logo">
        <div className={styles.version}>{pkg.version}</div>
        {account.user && account.user.root && <RootButton />}
      </div>
      <div className={styles.menu}>
        {menu.map((i, k) =>
          i.path ? (
            <Link key={k} to={i.path}>
              {i.title}
            </Link>
          ) : (
            <div key={k} onClick={e => i.action && i.action()}>
              {i.title}
            </div>
          )
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
      </div>
    </div>
  );
});

export default Menu;
