import React, { FC, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import config from "../config";
import { history, ui, account } from "../store";

import styles from "./App.module.scss";

import Loader from "./Loader";
import Message from "./Message";
import Login from "../pages/Login";
import Pin from "../pages/Pin";
import Menu from "../pages/Menu";
import Balance from "../pages/Balance";
import BalanceHistory from "../pages/BalanceHistory";
import Statistics from "../pages/Statistics";
import OrdersMenu from "../pages/OrdersMenu";
import OrdersHistory from "../pages/OrdersHistory";
import OrdersReserved from "../pages/OrdersReserved";
import OrdersReservedRequests from "../pages/OrdersReservedRequests";
import Finances from "../pages/Finances";
import Schedule from "../pages/Schedule";
import EmailDirector from "../pages/EmailDirector";
import EmailManager from "../pages/EmailManager";
import EmailMech from "../pages/EmailMech";
import Washing from "../pages/Washing";
import Profile from "../pages/Profile";
import BankDetails from "../pages/BankDetails";
import BankAccount from "../pages/BankAccount";
import Root from "../pages/Root";

const MENU = [
  {
    title: "Памятка",
    action: () => window.open(`${config.IST_URL}/user/memo`)
  },
  { title: "Профиль", path: "/profile" },
  { title: "Реквизиты", path: "/bankDetails" },
  { title: "Баланс", path: "/balance" },
  // { title: "Пополнения", path: "/balanceHistory" },
  { title: "Финансы", path: "/finances" },
  { title: "План-график", path: "/schedule" },
  { title: "Письмо менеджеру", path: "/emailManager" },
  { title: "Заявка на ремонт", path: "/emailMech" },
  { title: "Запись на мойку", path: "/washing" }
];

const AppPegas: FC = observer(() => {
  const [menu, setMenu] = useState(MENU);
  useEffect(() => {
    let menuPegas = MENU;
    window.document.title = "Личный кабинет водителя / Pegas-pro";
    if (account.user && !account.user.person.email && !account.user.root) {
      ui.setMessage({
        text:
        "Необходимо заполнить и подтвердить EMAIL. Зайдите в раздел профиль.<hr/> Данное сообщение будет появляться при каждом входе в личный кабинет и блокировать ваши действия на 15 секунд пока EMAIL не будет установлен.",
        type: "WARN",
        permanent: true,
        lifeTime: 1000 * 10
      });
    }
    if (account.user && account.user && account.user.garage) {
      const meta = JSON.parse(account.user.garage.metaJson);
      if (meta && !meta.wash) {
        menuPegas = menuPegas.filter(i => i.path !== "/washing");
      }
    }
    if (account.user && !account.user.isCaravan) {
      menuPegas = MENU.filter(i => i.path !== "/emailMech");
      menuPegas = menuPegas.filter(i => i.path !== "/schedule");
      menuPegas = menuPegas.filter(i => i.path !== "/washing");
    }
    if (account.user && !account.user.yandexId) {
      menuPegas = menuPegas.filter(i => i.path !== "/bankDetails");
    }
    setMenu(menuPegas);
    // eslint-disable-next-line
  }, [account.user]);
  return (
    <Router history={history}>
      <div className={[styles.container, "pegas"].join(" ")}>
        {ui.loading && <Loader />}
        {ui.message && (
          <Message
            text={ui.message}
            messageType={ui.messageType}
            permanent={ui.permanent}
            onClick={() => ui.permanent ? null : ui.setMessage({ text: "" })}
          />
        )}
        <Switch>
          <Route exact path="/login" component={Login} />
          {account.isAuth && (
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Menu menu={menu} />}
              />
              <Route exact path="/balance" component={Balance} />
              <Route exact path="/balanceHistory" component={BalanceHistory} />
              <Route exact path="/statistics" component={Statistics} />
              <Route exact path="/bankDetails" component={BankDetails} />
              <Route exact path="/bankAccount/:id" component={BankAccount} />
              <Route exact path="/ordersMenu" component={OrdersMenu} />
              <Route exact path="/orders/history" component={OrdersHistory} />
              <Route exact path="/orders/reserved" component={OrdersReserved} />
              <Route exact path="/orders/reserved/requests" component={OrdersReservedRequests} />
              <Route exact path="/finances" component={Finances} />
              <Route exact path="/schedule" component={Schedule} />
              <Route exact path="/emailDirector" component={EmailDirector} />
              <Route exact path="/emailManager" component={EmailManager} />
              <Route exact path="/emailMech" component={EmailMech} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/washing" component={Washing} />
              {account.user && account.user.root && (
                <Route exact path="/root" component={Root} />
              )}
              <Route component={() => <Menu menu={menu} />} />
            </Switch>
          )}
          {!ui.loading && <Route component={Pin} />}
        </Switch>
      </div>
    </Router>
  );
});

export default AppPegas;
