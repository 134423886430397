import React, { FC, useState, useEffect, useRef } from "react";
import { faCaretSquareDown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { ISelectItem } from "../models";

import styles from "./SelectGrid.module.scss";

interface ISelectGrid {
  title: string;
  items?: ISelectItem[];
  selected?: number;
  onSelect: Function;
}

const SelectGrid: FC<ISelectGrid> = ({
  title,
  items = [],
  selected = 0,
  onSelect
}) => {
  const el = useRef(null);
  const [selectedItem, setSelected] = useState(selected);
  const [subMenu, setSubMenu] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    if (!subMenu && items.length > 0) {
      setSubMenu(!subMenu);
    }
  };
  
  const selectItem = (k: number) => {
    setSubMenu(false);
    if (k !== selectedItem) {
      setSelected(k);
      onSelect(items[k].value, k);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside, false);
  }, []);

  useEffect(() => {
    if (selected >= 0) {
      setSelected(selected);
    }
  }, [selected]);

  const handleClickOutside = (e: MouseEvent) => {
    // @ts-ignore
    if (el && el.current && el.current.contains(e.target)) {
      return;
    } else {
      setSubMenu(false);
    }
  };
  return (
    <React.Fragment>
      {subMenu && <div className={styles.shadow} />}
      <div className={styles.container} ref={el} onClick={onClick}>
        <div>{title}</div>
        <div className={styles.downList}>
          {(items.length && selected > 0 && items[selectedItem].title) || "--"}
          <Icon icon={faCaretSquareDown} className={styles.icon} />
        </div>
        {subMenu && (
          <div className={styles.subMenuContainer}>
            <div className={styles.subMenu}>
              <div className={styles.subMenuTitle}>{title}</div>
              <div className={styles.subMenuContainer}>
                {items.map((i, k) => (
                  <div
                    className={[
                      styles.subMenuItem,
                      selectedItem === k && styles.selected,
                      i.disabled && styles.disabled
                    ].join(" ")}
                    key={k}
                    onClick={e => (i.disabled ? null : selectItem(k))}
                  >
                    {i.title}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectGrid;
