import React, { FC, useRef } from "react";
import moment from "moment";
import { IPlanRecord } from "../../../models";
import { IGrid } from "./Grid";
import styles from "./MonthGrid.module.scss";

interface IMonthGridEL {
  onSelect: Function;
  currentDay: moment.Moment;
  recordsInDay: IPlanRecord[];
  bgn: moment.Moment;
  end: moment.Moment;
}

const MonthGridEL: FC<IMonthGridEL> = ({
  onSelect,
  currentDay,
  recordsInDay,
  bgn,
  end
}) => {
  const el = useRef(null);
  const recordInDay =
    recordsInDay && recordsInDay.length > 0 && recordsInDay[0];
  return (
    <div
      ref={el}
      className={[
        styles.cell,
        recordsInDay && recordsInDay.length > 0 && styles.active,
        (currentDay < bgn || currentDay > end) && styles.unactive,
        moment().isSame(currentDay, "day") && styles.currentDay
      ].join(" ")}
      onClick={e => recordInDay && onSelect(el, recordInDay)}
    >
      <div className={styles.day}>{currentDay.format("D")}</div>
      <div className={styles.records}>
        {recordsInDay &&
          recordsInDay.map(i => <div key={i.id}>{i.carNom}</div>)}
      </div>
    </div>
  );
};

const MonthGrid: FC<IGrid> = ({ period, records, onSelect }) => {
  const week = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
  const start = moment(period.bgn).startOf("week");
  const stop = moment(period.end).endOf("week");
  const bgn = moment(period.bgn);
  const end = moment(period.end);
  const days = Math.round(moment.duration(stop.diff(start)).asDays());

  return (
    <div className={styles.calendar}>
      <div className={styles.month}>
        {week.map(i => (
          <div key={i} className={styles.header}>
            {i}
          </div>
        ))}

        {[...Array(days)].map((i, k) => {
          const currentDay = start.clone().add(k, "day");
          const recordsInDay = records.filter(
            i =>
              moment(i.openedAt).format("DD/MM/YYYY") ===
              currentDay.format("DD/MM/YYYY")
          );
          return (
            <MonthGridEL
              key={k}
              currentDay={currentDay}
              recordsInDay={recordsInDay}
              onSelect={onSelect}
              bgn={bgn}
              end={end}
            />
          );
        })}
      </div>
    </div>
  );
};


export default React.memo(MonthGrid, (prevProps: IGrid, nextProps: IGrid) => {
  return prevProps.records.length === nextProps.records.length && prevProps.period.bgn === nextProps.period.bgn;
});