import React, { FC, MouseEventHandler } from "react";

import styles from "./Message.module.scss";

interface IMessage {
  text: string;
  messageType?: "ERROR" | "INFO" | "WARN" | any;
  permanent?: boolean,
  onClick: MouseEventHandler;
}

const Message: FC<IMessage> = ({ text, messageType = "INFO", permanent, onClick }) => {
  return (
    <div className={[styles.container, permanent && styles.permanent].join(" ")} onClick={onClick}>
      <div
        className={[styles.message, styles[messageType]].join(" ")}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default Message;
