import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import AppVsegda from "./components/AppVsegda";
import AppKaravan from "./components/AppKaravan";
import AppPegas from "./components/AppPegas";
import * as serviceWorker from "./serviceWorker";
import moment from "moment";
// import "moment/min/moment-with-locales";
import "moment/min/locales";
import config from "./config";
moment.locale("ru");

ReactDOM.render(
  config.IS_PEGAS ? (
    <AppPegas />
  ) : config.IS_KARAVAN ? (
    <AppKaravan />
  ) : (
    <AppVsegda />
  ),
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
