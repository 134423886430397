import api from "./index";
import { IBankAccount, IBankAccounts, IBankBik } from "../models";

interface IPIN {
  pin?: number
}

export class AccountAPI {
  static async check(): Promise<any> {
    return api.request('GET', '/auth', {})
  }

  static async getPin(uin: string): Promise<IPIN> {
    return api.request('POST', '/auth/pin', {
      body: { uin }
    })
  }

  static async changeDriver(uin: string): Promise<any> {
    return api.request('POST', '/auth/changeDriver', {
      body: { uin }
    })
  }

  static async setEmail(email: string): Promise<any> {
    return api.request('POST', '/user/setEmail', {
      body: { email }
    })
  }

  static async getBankDetails(): Promise<IBankAccounts> {
    return api.request('GET', '/user/DriversBanksDetails', {})
  }

  static async setBankDetails(account: IBankAccount): Promise<any> {
    return api.request('PUT', `/user/DriversBanksDetails/update/${account.id}`, {
      body: {
        bankCardNumber: account.bankCardNumber,
        bankAccountNumber: account.bankAccountNumber,
        bankPurpose: account.bankPurpose,
        recipientName: account.recipientName,
        recipientType: account.recipientType,
        verified: account.verified,
        active: account.active,
        remarks: account.remarks,
        listAvailable: false
      }
    })
  }

  static createBankDetails(account: IBankAccount): Promise<any> {
    return api.request('POST', '/user/DriversBanksDetails/create', {
      body: {
        bankId: account.bankId,
        bankCardNumber: account.bankCardNumber,
        bankAccountNumber: account.bankAccountNumber,
        bankPurpose: account.bankPurpose,
        recipientName: account.recipientName,
        recipientType: account.recipientType
      }
    })
  }

  static async getBankByBik(bik: string): Promise<IBankBik> {
    return api.request('GET', '/user/getBankByBik', {
      query: { bik }
    })
  }

  static async login(method: string, login: string, secret: string): Promise<any> {
    return api.request('POST', '/auth', {
      body: { method, login, secret }
    })
  }

  static async logout(): Promise<any> {
    return api.request('DELETE', '/auth', {})
  }
}