import React, { FC, useState, useEffect } from "react";
import moment from "moment";
import { history, data } from "../../store";

import BasicFrame from "../../components/BasicFrame";
import Select from "../../components/Select";
import SelectGrid from "../../components/SelectGrid";

import styles from "./index.module.scss";
import { ICar, ISelectItem, ICarPossibility } from "../../models";

type IDate = [string, Date];

const Washing: FC = () => {
  const [cars, setCars] = useState<ICar[] | undefined>();
  const [car, setCar] = useState<ICar | undefined>();
  const [dates, setDates] = useState<Array<IDate> | undefined>();
  const [date, setDate] = useState<Date | undefined>();
  const [times, setTimes] = useState<ISelectItem[] | undefined>();
  const [time, setTime] = useState<Date | undefined>();
  const [schedules, setSchedules] = useState<ISelectItem[][] | undefined>();
  const [inspection, setInspection] = useState<ICarPossibility | undefined>();
  const [wash, setWash] = useState<ICarPossibility | undefined>();

  useEffect(() => {
    data.getCars().then(cars => {
      setCar(cars ? cars[0] : undefined);
      setCars(cars);
    });
  }, []);

  useEffect(() => {
    if (car) {
      const period = {
        bgn: moment().toDate(),
        end: moment()
          .add(1, "week")
          .endOf("day")
          .toDate()
      };
      data.findWashPossibilitiesAll(period, car && car.id).then(result => {
        if (result && result.schedule && result.schedule.length > 0) {
          setSchedules(result.schedule);
          const dates: Array<IDate> = result.schedule.map(
            (i: [ISelectItem]) => [
              moment(i[0].value).format("DD MMMM dddd"),
              moment(i[0].value)
                .startOf("date")
                .toDate()
            ]
          );
          setDates(dates);
          if (dates && dates.length > 0) {
            setDate(dates[0][1]);
          }
        } else {
          setDates(undefined);
          setDate(undefined);
          setTimes(undefined);
          setTime(undefined);
        }
        if (result && result.possibilities && result.possibilities.length > 0) {
          const inspection = result.possibilities.find(
            (i: ICarPossibility) => i.type === "INSPECTION"
          );
          if (inspection) {
            setInspection(inspection);
          }
          const wash = result.possibilities.find(
            (i: ICarPossibility) => i.type === "WASH"
          );
          if (wash) {
            setWash(wash);
          }
        } else {
          setWash(undefined);
        }
      });
    }
  }, [car]);

  useEffect(() => {
    if (dates && date && car && schedules) {
      let schedule = schedules.find(
        i => moment(i[0].value).toJSON() === date.toJSON()
      );
      if (schedule) {
        schedule = schedule.map((i: any) => ({
          ...i,
          value: moment(i.value).toDate()
        }));
        setTimes(schedule);
      }
    }
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (times) {
      let time_: ISelectItem | undefined;
      if (time) {
        time_ = times.find(i => i.value.toJSON() === time.toJSON());
        if (time_ && time_.disabled) {
          time_ = undefined;
        }
      }
      if (!time_) {
        time_ = times.find(i => !i.disabled);
      }
      if (time_) {
        setTime(time_.value);
      } else {
        setTime(undefined);
      }
    }
    // eslint-disable-next-line
  }, [times]);

  return (
    <BasicFrame title="Запись на Мойку">
      <div className={styles.container}>
        <div className={styles.form}>
          <select
            onChange={e => {
              if (cars) {
                const car = cars.find(i => i.id === parseInt(e.target.value));
                if (car) {
                  setCar(car);
                }
              }
            }}
          >
            {cars &&
              cars.map(i => (
                <option
                  key={i.id}
                  value={i.id}
                >{`${i.model} ${i.regNo}`}</option>
              ))}
          </select>
          <Select
            title="Дата"
            items={dates}
            selected={
              dates &&
              date &&
              dates.findIndex(i => i[1].toJSON() === date.toJSON())
            }
            onSelect={(i: Date, k: number) => {
              setDate(i);
            }}
          />
          <SelectGrid
            title="Время"
            items={times}
            selected={
              times &&
              time &&
              times.findIndex(i => i.value.toJSON() === time.toJSON())
            }
            onSelect={(i: Date, k: number) => setTime(i)}
          />
          {inspection && !wash && (
            <p className={styles.info}>
              {`${moment(inspection.beginedAt).format(
                "DD MMMM (dddd)"
              )} с ${moment(inspection.beginedAt).format("HH:mm")} до ${moment(
                inspection.endedAt
              ).format(
                "HH:mm"
              )} у вас запланирован контрольный осмотр. Хотите совместить его с мойкой автомобиля?`}
              <button
                onClick={e => {
                  const date = moment(inspection.endedAt).startOf("minute");
                  setDate(
                    date
                      .clone()
                      .startOf("day")
                      .toDate()
                  );
                  setTime(date.toDate());
                  data
                    .addCarPossibility({
                      carId: car && car.id,
                      type: "WASH",
                      beginedAt: date.toJSON(),
                      endedAt: moment(date)
                        .add(30, "minute")
                        .toJSON()
                    })
                    .then(result => {
                      setWash(result);
                    });
                }}
              >
                Да
              </button>
            </p>
          )}
          {wash && (
            <p className={styles.info}>
              {`Вы уже записаны на мойку автомобиля на ${moment(
                wash.beginedAt
              ).format("DD MMMM (dddd)")} в ${moment(wash.beginedAt).format(
                "HH:mm"
              )}. Хотите отменить или перенести запись?`}
              <button
                onClick={e =>
                  wash.id &&
                  data.removeCarPossibility(wash.id).then(result => {
                    setWash(undefined);
                  })
                }
              >
                Да
              </button>
            </p>
          )}
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
        {!wash && car && time && (
          <button
            onClick={e =>
              data
                .addCarPossibility({
                  carId: car && car.id,
                  type: "WASH",
                  beginedAt: time.toJSON(),
                  endedAt: moment(time)
                    .add(30, "minute")
                    .toJSON()
                })
                .then(result => {
                  setWash(result);
                })
            }
          >
            Записаться
          </button>
        )}
      </div>
    </BasicFrame>
  );
};

export default Washing;
