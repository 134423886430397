import React, { FC, useEffect, useState } from "react";
import moment from "moment";
// import _ from "lodash";

import { data } from "../../store";
import BasicFrame from "../../components/BasicFrame";

import styles from "./index.module.scss";
import { IPeriod, IPlanRecords, IPlanRecord, IMode } from "../../models";
import Toolbar from "./components/Toolbar";
import Title from "./components/Title";
import PopUp, { IPopUp } from "./components/PopUp";
import MonthGrid from "./components/MonthGrid";
import WeekGrid from "./components/WeekGrid";

const Schedule: FC = () => {
  const [popup, setPopup] = useState<IPopUp>({ show: false });
  const [planRecords, setPlanRecords] = useState<IPlanRecords | undefined>();
  const [currentMode, setCurrentMode] = useState<IMode>("month");
  const [currentPeriod, setCurrentPeriod] = useState<IPeriod>({
    bgn: moment()
      .startOf("month")
      .toDate(),
    end: moment()
      .endOf("month")
      .toDate()
  });

  useEffect(() => {
    data.getPlanRecords(currentPeriod).then(setPlanRecords);
  }, [currentPeriod]);

  useEffect(() => {
    let bgn = moment(currentPeriod.bgn);
    let end = moment();
    if (currentMode === "month") {
      bgn = bgn.clone().startOf("month");
      end = bgn.clone().endOf("month");
    } else {
      bgn = moment().startOf("week");
      end = moment().endOf("week");
    }
    if (!(bgn.isSame(currentPeriod.bgn) && end.isSame(currentPeriod.end))) {
      setCurrentPeriod({ bgn: bgn.toDate(), end: end.toDate() });
    }
    // eslint-disable-next-line
  }, [currentMode]);

  const changePeriod = (direction: -1 | 1, mode: "month" | "week") => {
    setPlanRecords(undefined);
    let bgn = moment(currentPeriod.bgn);
    let end = moment(currentPeriod.end);
    bgn = bgn.add(direction, mode).startOf(mode);
    end = end.add(direction, mode).endOf(mode);
    setCurrentPeriod({ bgn: bgn.toDate(), end: end.toDate() });
  };

  const showPopUp = (
    el: React.RefObject<HTMLDivElement>,
    record: IPlanRecord
  ) => {
    setPopup({
      show: true,
      period: `${moment(record.openedAt).format("DD.MM HH:mm")} - ${moment(
        record.closedAt
      ).format("DD.MM HH:mm")}`,
      car: `${record.carColorTitle} ${record.carModelTitle} ${record.carNom}`,
      tariff: record.tariffTitle,
      random: Math.random(),
      // @ts-ignore
      y: el.current.offsetTop,
      // @ts-ignore
      x: el.current.offsetLeft
    });
  };

  return (
    <BasicFrame title="План-график">
      <PopUp {...popup} />
      <div className={styles.container}>
        <Toolbar
          currentMode={currentMode}
          toggleMode={(mode: IMode) => {
            setPlanRecords(undefined);
            setCurrentMode(mode);
          }}
        />
        <Title
          currentPeriod={currentPeriod}
          next={() => changePeriod(1, currentMode)}
          prev={() => changePeriod(-1, currentMode)}
        />
        {currentMode === "month" ? (
          <MonthGrid
            period={currentPeriod}
            records={planRecords ? planRecords.data : []}
            onSelect={showPopUp}
          />
        ) : (
          <WeekGrid
            period={currentPeriod}
            records={planRecords ? planRecords.data : []}
            onSelect={showPopUp}
          />
        )}
      </div>
    </BasicFrame>
  );
};

export default Schedule;
