import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import BackButton from "../../components/BackButton";
import { account } from "../../store";

import styles from "./index.module.scss";

interface IRoot {}

const Root: FC<IRoot> = observer(() => {
  const [value, setValue] = useState("");
  return (
    <div className={styles.container}>
      <div className="logo">
        <BackButton />
      </div>
      <div className={styles.content}>
        <p className={styles.info}>
          У вас есть возможность войти под любым водителем. Для этого введите
          его платёжный код и нажмите кнопку "Войти".
        </p>
        <label>Введите платёжный код</label>
        <input
          type="number"
          placeholder="Код"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </div>
      <div className={styles.buttonContent}>
        <button onClick={e => account.changeDriver(value)}>Войти</button>
      </div>
    </div>
  );
});

export default Root;
