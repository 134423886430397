const IS_PEGAS =
  process.env.REACT_APP_PEGAS_DOMAIN === window.location.hostname;

const IS_KARAVAN =
  process.env.REACT_APP_KARAVAN_DOMAIN === window.location.hostname;

const common = {
  IS_PEGAS: IS_PEGAS,
  IS_KARAVAN: IS_KARAVAN,
};

const prod = {
  IST_URL: IS_PEGAS
    ? "https://api-driver.pegas-pro.ru"
    : IS_KARAVAN
    ? "https://driver.karavanservice.ru"
    : "https://driver.taxi-vsegda.ru",
  SUBSCRIBE_USER_TO_PUSH: false,
};

// export default process.env.NODE_ENV === 'production' ? {...common, ...prod} : {...common, ...dev};
// export default process.env.NODE_ENV === 'development' ? {...common, ...prod} : {...common, ...dev};

export default { ...common, ...prod };
