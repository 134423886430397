function pluralize(count: number, words: string[]): string {
  // 1,2,5
  var cases = [2, 0, 1, 1, 1, 2];
  return words[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]];
}

function remarks2string(remarks: string) {
  const matched = remarks.match(/\[r:(.*?)\]/);
  return matched && matched.length > 0 ? matched[1] : null;
}

export { pluralize, remarks2string };