import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { faDotCircle, faClock as faIntersected, faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { remarks2string, pluralize } from "../../utils";

import { history, data } from "../../store";
import BasicFrame from "../../components/BasicFrame";
import Dialog from "../../components/Dialog";

import styles from "./index.module.scss";
import { IPeriod, IOrder } from "../../models";

const Route: FC<{ src: string; dst: string; count: number }> = ({
  src,
  dst,
  count
}) => {
  const interimAdds = `${count - 2} ${pluralize(count - 2, ['промежуточный адрес', 'промежуточных адреса', 'промежуточных адресов'])}`;
  return (
    <div className={styles.route}>
      <div className={styles.addsContainer}>
        <div className={styles.iconContainer}>
          <Icon icon={faDotCircle} className={styles.icon} />
        </div>
        <div className={styles.adds}>{src}</div>
      </div>
      {count > 2 && (
        <div className={styles.addsContainer}>
          <div className={styles.iconContainer}>
            <Icon icon={faDotCircle} className={styles.icon} />
          </div>
          <div className={styles.adds}>{interimAdds}</div>
        </div>
      )}
      <div className={styles.addsContainer}>
        <div className={styles.iconContainer}>
          <Icon icon={faDotCircle} className={styles.icon} />
        </div>
        <div className={styles.adds}>{dst || "Не определён"}</div>
      </div>
    </div>
  );
};

const Order: FC<IOrder> = ({
  id,
  assignedAt,
  beginedAt,
  doneAt,
  cost,
  remarks,
  expectedCost,
  costCorrected,
  costDriver,
  costDriverCorrected,
  addressSourceTitle,
  addressDestTitle,
  routeActualCount,
  requests,
  intersected,
  handler
}) => {
  const remarksString = remarks && remarks2string(remarks);
  return (
    <div
      className={[styles.order, requests && requests.length > 0 ? styles.requested : intersected ? styles.intersected : ""].join(" ")}
      onClick={e => handler && handler({
        id,
        assignedAt,
        beginedAt,
        doneAt,
        cost,
        expectedCost,
        costCorrected,
        costDriver,
        costDriverCorrected,
        addressSourceTitle,
        addressDestTitle,
        routeActualCount,
        requests,
        intersected,
      })}
    >
      <Icon icon={requests && requests.length > 0 ? faCheckCircle : intersected ? faIntersected : faDotCircle} className={styles.stateIcon}></Icon>
      <div className={styles.line}>
        <div className={styles.time}>
          {moment(assignedAt).format("HH:mm")}
        </div>
        <div className={styles.cost}>
          {`${expectedCost || "--"} ₽`}
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.tiny}>
          №{id}
        </div>
        {remarksString && <div className={styles.remarks}> {remarksString} </div>}
      </div>
      <Route
        src={addressSourceTitle}
        dst={addressDestTitle}
        count={routeActualCount}
      />
    </div>
  );
};

interface IDayStat {
  shift: IPeriod;
  handler: Function;
}

const DayStat: FC<IDayStat> = ({ shift, handler }) => {
  return (
    <React.Fragment>
      <div className={styles.title}>
        {moment(shift.bgn).format("DD MMMM YYYY")}
      </div>
      <div className={styles.orders}>
        {shift.orders && shift.orders.sort((a, b) => a.assignedAt < b.assignedAt ? -1 : 1).map((i, k) => <Order key={k} {...i} handler={handler} />)}
      </div>
    </React.Fragment>
  );
};

const OrdersReserved: FC = () => {
  const [orders, setOrders] = useState<IOrder[] | null>();
  const [shifts, setShifts] = useState<IPeriod[] | null>();

  useEffect(() => {
    data.getReservedOrdersList(true, false).then(setOrders);
  }, []);

  useEffect(() => {
    if (orders) {
      const shifts = orders.reduce((s: IPeriod[], i) => {
        const day = moment(i.assignedAt)
          .startOf("day")
          .toDate();
        const shift = s.find((p: IPeriod) => p.bgn.toJSON() === day.toJSON());
        if (shift) {
          if (shift.orders) {
            shift.orders.push(i);
          }
        } else {
          s.push({
            bgn: day,
            end: day,
            orders: [i]
          });
        }
        return s;
      }, []);
      setShifts(shifts);
    }
  }, [orders]);

  const [order, setOrder] = useState<IOrder | null>();
  const [showDialog, setShowDialog] = useState(false);

  const orderClick = async (order: IOrder) => {
    if (order.intersected) return;
    try {
      if (!order.requests || order.requests.length === 0) {
        const request = await data.createReservedOrdersRequest(order);
        if (orders) {
          const order_ = orders.find(i => i.id === request.orderId);
          if (order_) {
            order_.requests = [{
              id: request.id,
              orderId: request.orderId,
              driverId: request.orderId
            }];
            setOrders(orders);
          }
        }
      } else {
        if (orders) {
          const order_ = orders.find(i => i.id === order.id);
          if (order_) {
            setOrder(order);
            setShowDialog(true);
            // order_.requests = [];
            // setOrders(orders);
          }
        }
      }
    } catch (ex) { }
    data.getReservedOrdersList(false, false).then(setOrders);
  }
  return (
    <BasicFrame title="Предварительные заказы">
      <Dialog title="Подтверждение" show={showDialog}>
        <span>
          Действительно отозвать заявку на заказ {`${order && order.addressSourceTitle} к ${moment(order ? order.assignedAt : "").format("HH:mm dddd DD MMM YYYY")}`}?
        </span>
        <div className={styles.dialogButtonsContainer}>
          <button onClick={e => setShowDialog(false)} className="secondary">
            Отмена
          </button>
          <button
            onClick={async e => {
              setShowDialog(false);
              if (order && orders) {
                const order_ = orders.find(i => i.id === order.id);
                if (order_) {
                  await data.removeReservedOrdersRequest(_.first(order.requests).id);
                  order_.requests = [];
                  setOrders(orders);
                }
                setOrder(null);
                data.getReservedOrdersList(false, false).then(setOrders);
              }
            }}
          >
            Отозвать
          </button>
        </div>
      </Dialog>
      <div className={styles.container}>
        {shifts &&
          shifts
            .sort((a, b) => (a.bgn > b.end ? 1 : -1))
            .map((i: IPeriod, k: number) => <DayStat key={k} shift={i} handler={orderClick} />)}
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
      </div>
    </BasicFrame>
  );
};

export default OrdersReserved;
