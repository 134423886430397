import { observable, computed, action } from "mobx";

interface IMessage {
  text: string;
  type?: "ERROR" | "INFO" | "WARN";
  permanent?: boolean;
  lifeTime?: number;
}

class UI {
  @observable loadingCount: number = 0;
  @observable message: string = "";
  @observable messageType: string = "INFO";
  @observable permanent: boolean = false;
  @observable online: boolean = true;

  interval: NodeJS.Timeout | null = null;

  @action 
  setMessage({text, type = "INFO", lifeTime, permanent = false}: IMessage) {
    this.message = text;
    this.permanent = permanent;
    this.messageType = type;
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (text && lifeTime) {
      this.interval = setTimeout(() => this.setMessage({text: ""}), lifeTime);
    }
  }

  @computed
  get loading(): boolean {
    return this.loadingCount !== 0;
  }

  set loading(x: boolean) {
    if (x) {
      this.loadingCount++;
    } else {
      setTimeout(() => this.loadingCount--, 100);
    }
  }

}

export const ui = new UI();
